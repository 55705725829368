import { computed, decorate, observable, action } from "mobx"
import React from "react"

class BenchmarkStore {
  hasStarted = false
  keys = ["Z", "X"]
  bpm = 0
  bpms = []
  beginTime
  timeLeft = 0
  pressedKey = -1
  clicksAmount = 0
  mouseEnabled = false
  goal = 180
  hasFinishedDecreasing = true
  unstableRate = 0
  time = 10

  get backgroundColor() {
    if ((this.bpm >= 0 && this.bpm <= 140) || this.bpm < 0) {
      return "#9BE8FF"
    }

    if (this.bpm >= 141 && this.bpm <= 160) {
      return "#8AFFCB"
    }

    if (this.bpm >= 161 && this.bpm <= 180) {
      return "#D7FF85"
    }

    if (this.bpm >= 181 && this.bpm <= 200) {
      return "#FFCC55"
    }

    if (this.bpm >= 201 && this.bpm <= 210) {
      return "#FFB5A6"
    }

    if (this.bpm >= 210 && this.bpm <= 250) {
      return "#E284FC"
    }

    return "#000000"
  }

  reset() {
    this.pressedKey = -1

    if (!this.hasStarted) {
      this.clicksAmount = 0
      this.hasFinishedDecreasing = false
      const intrvl = setInterval(() => {
        if (this.bpm - 5 < 0) {
          this.bpm = 0
          this.hasFinishedDecreasing = true
          clearInterval(intrvl)
          return
        }

        this.bpm -= 5
      }, 10)
    }

    this.hasStarted = !this.hasStarted
  }
}

decorate(BenchmarkStore, {
  hasStarted: observable,
  hasFinishedDecreasing: observable,
  keys: observable,
  mouseEnabled: observable,
  bpm: observable,
  bpms: observable,
  clicksAmount: observable,
  backgroundColor: computed,
  time: observable,
  beginTime: observable,
  pressedKey: observable,
  timeLeft: observable,
  goal: observable,
  unstableRate: observable,
  reset: action
})

export default React.createContext(new BenchmarkStore())
