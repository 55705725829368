import styled from "@emotion/styled/macro"
import { observer } from "mobx-react-lite"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import React from "react"
import { animated, useTransition } from "react-spring"
import "./assets/np.css"
import BenchmarkStore from "./BenchmarkStore"
import Benchmark from "./components/Benchmark"
import Settings from "./components/Settings"
import useMedia from "./hooks/useMedia"

const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: row;
  transition: background-color 1s ease;
  background-color: ${props => props.bg};

  @media (max-width: 768px) {
    flex-direction: column;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${props => props.bg};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${props => props.bg}, 0 0 5px ${props => props.bg};
    opacity: 1;

    transform: rotate(3deg) translate(0, -4px);
  }

  @media (min-width: 768px) {
    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;

      border: solid 2px transparent;
      border-top-color: ${props => props.bg};
      border-left-color: ${props => props.bg};
      border-radius: 50%;

      animation: nprogress-spinner 400ms linear infinite;
    }
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const App = observer(() => {
  const benchmarkStore = React.useContext(BenchmarkStore)
  const isPC = useMedia("(min-width: 768px)")

  React.useEffect(() => {
    let updater, stopper

    const onKeyUp = e => {
      if (e.keyCode === 27) {
        benchmarkStore.hasStarted = false
        return
      }

      if (!benchmarkStore.mouseEnabled) {
        const pressedKey = e.key.toUpperCase()
        if (
          (pressedKey === benchmarkStore.keys[0] ||
            pressedKey === benchmarkStore.keys[1]) &&
          benchmarkStore.hasFinishedDecreasing
        ) {
          benchmarkStore.pressedKey = benchmarkStore.keys.indexOf(pressedKey)
          benchmarkStore.clicksAmount++

          if (!updater) {
            startTimer()
          }
        }
      }
    }

    const onMouseUp = e => {
      if (benchmarkStore.hasFinishedDecreasing) {
        window.document.oncontextmenu = e => {
          e.preventDefault()
          e.stopPropagation()
        }

        if (e.button === 1) {
          return
        }

        const btn = !e.button ? 0 : 1
        benchmarkStore.pressedKey = btn
        benchmarkStore.clicksAmount++

        if (!updater) {
          startTimer()
        }
      }
    }

    const startTimer = () => {
      NProgress.start()
      NProgress.configure({ minimum: 0.01 })
      NProgress.set(0.0)

      benchmarkStore.beginTime = Date.now()

      updater = setInterval(() => {
        const time = Date.now() - benchmarkStore.beginTime
        NProgress.set(time / benchmarkStore.time / 1000)

        benchmarkStore.timeLeft = Math.abs(
          (
            (benchmarkStore.beginTime +
              benchmarkStore.time * 1000 -
              Date.now()) /
            1000
          ).toFixed(2)
        )

        const bpm = Math.floor(
          ((benchmarkStore.clicksAmount / time) * 1000 * 60) / 4
        )

        benchmarkStore.bpm = bpm

        if (!benchmarkStore.bpms.length) {
          benchmarkStore.bpms.push(bpm)
          return
        }

        // inserts sorted
        for (let i = 0; i < benchmarkStore.bpms.length; i++) {
          if (benchmarkStore.bpms[i] > bpm) {
            // insert at the index
            benchmarkStore.bpms.splice(i, 0, bpm)
            break
          }
        }

        // this code is wrong
        // let sum = benchmarkStore.bpms.reduce((a, b) => a + b);
        // let avg = sum / benchmarkStore.bpms.length;
        // let sigma = Math.sqrt(
        //   benchmarkStore.bpms.reduce((a, b) => (b - avg) ** 2 + a) /
        //     benchmarkStore.bpms.length -
        //     1
        // );

        // benchmarkStore.unstableRate = (sigma * 10).toFixed(2);
      }, 16.6)

      stopper = setTimeout(() => {
        benchmarkStore.reset()
        clearInterval(updater)
        window.document.oncontextmenu = null
      }, benchmarkStore.time * 1000)
    }

    if (benchmarkStore.hasStarted) {
      window.addEventListener("keyup", onKeyUp)

      if (benchmarkStore.mouseEnabled) {
        // TODO: forward a ref to the <Benchmark /> component and the event listener to it
        window.addEventListener("mouseup", onMouseUp)
      }
    }

    return () => {
      clearInterval(updater)
      clearTimeout(stopper)
      window.removeEventListener("keyup", onKeyUp)
      window.removeEventListener("mouseup", onMouseUp)
      NProgress.done()
    }
  }, [benchmarkStore.hasStarted, benchmarkStore.keys])

  // const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const menuTransition = useTransition(!benchmarkStore.hasStarted, null, {
    from: {
      opacity: 0,
      transform: "translate3d(-30px,0,0)"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0,0)"
    },
    leave: {
      opacity: 0,
      transform: "translate3d(-30px,0,0)",
      position: "absolute"
    }
  })

  const benchmarkAnimation = useTransition(!benchmarkStore.hasStarted, null, {
    from: {
      transform: "translate3d(0,0,0)"
    },
    enter: {
      transform: "translate3d(0,0,0)"
    },
    leave: {
      transform: "translate3d(0,0,0)"
    }
  })

  const stopTestAnimation = useTransition(benchmarkStore.hasStarted, null, {
    from: {
      opacity: 0,
      transform: "translate3d(0,-50px,0)"
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0,0)"
    },
    leave: {
      opacity: 0,
      transform: "translate3d(0,-50px,0)"
    }
  })

  const stopTest = () => {
    benchmarkStore.reset()
  }

  return (
    <AppWrapper bg={benchmarkStore.backgroundColor}>
      {stopTestAnimation.map(({ key, item, props }) =>
        item ? (
          <animated.div
            key={key}
            style={{
              ...props,
              position: "absolute",
              textAlign: "center",
              width: "100%",
              marginTop: "1rem",
              zIndex: 999,
              fontSize: "1.25rem",
              color: "white",
              fontWeight: "bold"
            }}
          >
            <div onClick={stopTest}>
              Press ESC or click here to stop the test!
            </div>
          </animated.div>
        ) : null
      )}
      {menuTransition.map(({ key, item, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            <Settings />
          </animated.div>
        ) : null
      )}
      {benchmarkAnimation.map(({ key, props, item }) =>
        item ? (
          <animated.div key={key} style={{ ...props, display: "flex" }}>
            <Benchmark />
          </animated.div>
        ) : (
          <animated.div
            key={key}
            style={{ ...props, display: "flex", width: "100%" }}
          >
            <Benchmark />
          </animated.div>
        )
      )}
    </AppWrapper>
  )
})

export default App
