import React from "react"
import styled from "@emotion/styled/macro"

// https://www.cssscript.com/realistic-ios-switch-pure-css/

const Circle = styled.i`
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;

  :before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }

  :after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
`

const Checkbox = styled.input`
  display: none;

  :checked + i {
    background-color: ${props => props.bg};
  }

  :checked + ${Circle}:before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }

  :checked + ${Circle}:after {
    transform: translate3d(22px, 2px, 0);
  }
`

const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  user-select: none;

  :active ${Circle}:after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }

  :active ${Checkbox} + ${Circle}:after {
    transform: translate3d(16px, 2px, 0);
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export default ({ label, onChange, value, bg = "#4BD763" }) => {
  return (
    <Label>
      <Checkbox type="checkbox" onChange={onChange} bg={bg} checked={value} />
      <Circle />
      {label}
    </Label>
  )
}
