import styled from "@emotion/styled/macro"
import { observer } from "mobx-react-lite"
import React from "react"
import BenchmarkStore from "../BenchmarkStore"
import Checkbox from "../components/Checkbox"

const SettingsContainer = styled.div`
  flex-grow: 1;
  background-color: #fff;
  min-height: 100vh;
  width: 50vw;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 6%;
  padding-top: 1%;

  @media (max-width: 768px) {
    width: 100vw;
  }
`

const Label = styled.label`
  width: 100%;
  display: inline-block;
  font-weight: bold;
  font-size: 1.65rem;
  color: #555;
  transition: color 1s ease;
`

const TimeLabel = styled(Label)`
  margin-top: 7%;
  margin-bottom: 1rem;
`

const TargetLabel = styled(Label)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const KeysLabel = styled(TargetLabel)`
  margin-bottom: 0;
`

const Header = styled.h1`
  margin: 0;
  color: #555;
  font-size: 4rem;
  transition: color 1s ease;
`

const FormInput = styled.input`
  border: none;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  font-size: 3rem;
  font-weight: bold;
  color: #555;
  transition: color 1s ease;
`

const TimeInput = styled(FormInput)`
  padding: 2%;
  width: 100%;
  transition: box-shadow 0.2s ease-in, color 1s ease,
    background-color 0.2s ease-in, color 1s ease;

  :focus {
    outline: none;
    box-shadow: 0px 4px 4px #aaa;
  }

  :disabled {
    background-color: #f8f8f8;
  }
`

const MouseInput = Checkbox

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const StartButton = styled(FormInput)`
  padding: 2%;
  text-align: center;
  color: #555;

  :focus {
    outline: none;
    box-shadow: 0px 4px 4px #aaa;
  }
`

const Keys = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  input:not(:first-of-type),
  label {
    margin: 1rem;
  }
`

const Key = styled(FormInput)`
  width: 6rem;
  padding: 2%;
  text-align: center;
  transition: box-shadow 0.2s ease-in, color 1s ease,
    background-color 0.2s ease-in, color 1s ease;

  :focus {
    outline: none;
    box-shadow: 0px 4px 4px #aaa;
  }

  :disabled {
    background-color: #f8f8f8;
  }
`

const Form = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Settings = observer(() => {
  const benchmarkStore = React.useContext(BenchmarkStore)

  return (
    <SettingsContainer>
      <Form>
        <Header>osu! speed benchmark</Header>
        <TimeLabel htmlFor="time-input">Time (in seconds)</TimeLabel>
        <TimeInput
          type="number"
          disabled={benchmarkStore.hasStarted}
          min="10"
          max="300"
          name="time-input"
          value={benchmarkStore.time}
          onChange={e =>
            +e.target.value <= 300
              ? (benchmarkStore.time = e.target.value)
              : null
          }
        />
        <KeysLabel>Keys</KeysLabel>
        <ButtonsWrapper>
          <Keys>
            <Key
              maxLength={1}
              disabled={
                benchmarkStore.hasStarted || benchmarkStore.mouseEnabled
              }
              value={benchmarkStore.keys[0]}
              onChange={e => {
                const key = e.target.value.toUpperCase()
                if (benchmarkStore.keys[1] !== key) {
                  benchmarkStore.keys[0] = key
                }
              }}
            />
            <Key
              maxLength={1}
              disabled={
                benchmarkStore.hasStarted || benchmarkStore.mouseEnabled
              }
              value={benchmarkStore.keys[1]}
              onChange={e => {
                const key = e.target.value.toUpperCase()
                if (benchmarkStore.keys[0] !== key) {
                  benchmarkStore.keys[1] = key
                }
              }}
            />
            <MouseInput
              bg={benchmarkStore.backgroundColor}
              value={benchmarkStore.mouseEnabled}
              label="Mouse buttons enabled?"
              onChange={() => {
                benchmarkStore.mouseEnabled = !benchmarkStore.mouseEnabled
                if (benchmarkStore.mouseEnabled) {
                  benchmarkStore.keys[0] = "🖱"
                  benchmarkStore.keys[1] = "🖱"
                  return
                }

                // TODO: use localStorage for last keys
                benchmarkStore.keys[0] = "Z"
                benchmarkStore.keys[1] = "X"
              }}
            />
          </Keys>
          <StartButton
            type="button"
            value="GO!"
            onClick={() => {
              benchmarkStore.reset()
            }}
          />
        </ButtonsWrapper>
      </Form>
    </SettingsContainer>
  )
})

export default Settings
