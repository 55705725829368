import { keyframes } from "@emotion/core"

export default keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(100%);
  }
`
