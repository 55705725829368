import styled from "@emotion/styled/macro"
import { observer } from "mobx-react-lite"
import PropTypes from "prop-types"
import React from "react"
import BenchmarkStore from "../BenchmarkStore"

const KeyWrapper = styled.div`
  font-size: 5rem;
  font-weight: bold;
  height: ${props => (props.isPressed ? "9rem" : "10rem")};
  width: ${props => (props.isPressed ? "9rem" : "10rem")};
  padding: 5%;
  user-select: none;
  color: ${props => props.bg};
  background-color: ${props => (props.isPressed ? "#ffffffff" : "#ffffffbf")};
  transition: color 1s ease, width 0.4s ease, height 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
`

const Key = observer(({ character, isPressed }) => {
  const benchmarkStore = React.useContext(BenchmarkStore)

  return (
    <KeyWrapper
      bg={benchmarkStore.backgroundColor}
      isPressed={isPressed}
      onTouchStart={() => {
        if (benchmarkStore.hasStarted) {
          window.dispatchEvent(
            new KeyboardEvent("keyup", { key: character.toUpperCase() })
          )
        }
      }}
    >
      {character.toUpperCase()}
    </KeyWrapper>
  )
})

Key.propTypes = {
  character: PropTypes.string.isRequired,
  isPressed: PropTypes.bool.isRequired
}

export default Key
