import styled from "@emotion/styled/macro"
import { observer } from "mobx-react-lite"
import React from "react"
import BenchmarkStore from "../BenchmarkStore"
import Key from "../components/Key"
import Scale from "../animations/Scale"

const BackgroundWrapper = styled.div`
  flex-grow: 1;
  height: 100vh;
  min-width: 50vw;
  z-index: 1;
  display: flex;
  padding: 1rem;
  flex-direction: column;
`

const BPM = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  user-select: none;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-variant-numeric: tabular-nums;
  text-align: center;
  font-size: 9rem;
  font-weight: bold;
  animation: ${Scale} 0.6s cubic-bezier(0.68, 0, 0.265, 1);
`

const TimeLeft = styled.span`
  font-size: 3.2rem;
  user-select: none;
  font-weight: normal;
`

const Keys = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${Scale} 0.6s cubic-bezier(0.68, 0, 0.265, 1);

  div:first-of-type {
    margin-right: 5%;
  }

  div:last-child {
    margin-right: 0;
  }
`

const Background = observer(() => {
  const benchmarkStore = React.useContext(BenchmarkStore)

  return (
    <>
      <BackgroundWrapper>
        <BPM>
          {benchmarkStore.bpm} BPM
          <TimeLeft>
            {benchmarkStore.hasStarted &&
              benchmarkStore.hasFinishedDecreasing && (
                <>time left: {benchmarkStore.timeLeft}</>
              )}
          </TimeLeft>
        </BPM>
        <Keys>
          {benchmarkStore.keys.map((k, i) => (
            <Key
              isPressed={benchmarkStore.pressedKey === i}
              character={k}
              key={i}
            />
          ))}
        </Keys>
      </BackgroundWrapper>
    </>
  )
})

export default Background
